import React, { Children } from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../actions';

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: theme.shadows[5],
    padding: '0px',
    '&:focus':{
      outline:'0px',
      }
  },

}));
const ModalBox = props => {
//console.log(props.activeHideModal)

    const handleClose = () => {
      if(props.activeHideModal == true){
  
      }else{
        props.hideModal()
      }
    };


const classes = useStyles();
//console.log(props.ShowModal)
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.ShowModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.ShowModal}>
          <div className={classes.paper}>
            {props.contentModal}
          </div>
        </Fade>
      </Modal>

    </div>
  );
}

const mapStateToProps = state =>{
  return{
    ShowModal: state.ShowModal,
    contentModal: state.contentModal,
    activeHideModal:state.activeHideModal
  };
};
const mapDispatchToProps = {
  hideModal,
  }
export default connect(mapStateToProps, mapDispatchToProps)(ModalBox);