import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import NotFound from '../containers/NotFound';
import Landing from '../containers/Landing';
import Layout from '../components/Layout';
import PDFView from '../containers/PDFView'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
const makeURLIn = window.location.href;

ReactPixel.init('305418114033766');
ReactPixel.pageView(); // For tracking page view
// ReactPixel.track(event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9

// const tagManagerArgs = {
//   gtmId: 'GTM-W7X7HZT'
// }
//TagManager.initialize(tagManagerArgs)
/***KIA - ANALYTICS***/
ReactGA.initialize('UA-173445859-2');
ReactGA.pageview(window.location.pathname + window.location.search);
//'GTM-W7X7HZT
const App = () => {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path='/:medio?' component={Landing} />
          <Route exact path='/L1/:medio?' component={Landing} />
          <Route exact path='/L2/:medio?' component={Landing} />
          <Route exact path='/PDF/:landing/:name/:celular/:company' component={PDFView} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </Router>
  );
};

export default App;
