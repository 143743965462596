import React, {useState} from 'react'
import {Link ,useHistory} from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import ButtonSend from '../assets/static/boton_enviar.svg'
import ReactGA from 'react-ga';
import Button from '@material-ui/core/Button';
import LogoFinal from '../assets/static/logoFinal.svg'
const ContactForm = ({medio})=>{
    const inp_field = {
        company: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð&()@# ,.'0-9-]+$/i,
        name: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'0-9-]+$/i,
        fullname:/^([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\']+[\s])+([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])+[\s]?([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])?$/i,
        email:/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
    }
    let history = useHistory()
    const [isError,setIsError]=useState({"name":false,"restaurante":false,"celular":false,"email":false})
    const [dataForm, SetDataForm]=useState({"name":'',"restaurante":'',"celular":'',"email":''})
    const handleDataForm=(event, regex)=>{
        if(regex.test(event.target.value)){
            //console.log(event.target.name)
            setIsError({
                ...isError,
                [event.target.name]:false
            })
        }else{
            //console.log('false')
            setIsError({
                ...isError,
                [event.target.name]:true
            })
        }
        SetDataForm({
            ...dataForm,
            [event.target.name]:event.target.value
        })
    }
    const handleSendEmail = ()=>{
    let rutaActual = history.location.pathname
    rutaActual = rutaActual.replace(`${medio}`,'')
    console.log(history.location.pathname)
    let LandingActual = ''
    if(rutaActual == '/'){
        LandingActual = 'CalculadoraGastos'
    }
    if(rutaActual == '/L1/'){
        LandingActual = 'CalculadoraAhorro'
    }
    if(rutaActual == '/L2/'){
        LandingActual = 'NecesitasOrderit'
    }
        const {name,email,restaurante,celular}=isError
        console.log(name)
        if(name == false && email == false && restaurante == false && celular == false){
            if(dataForm.name != '' && dataForm.email !='' && dataForm.celular != '' && dataForm.restaurante != ''){
                ReactGA.initialize('UA-173445859-2');
                SetLoading(true)
                var formdata = new FormData();
                formdata.append("name", dataForm.name);
                formdata.append("company", dataForm.restaurante);
                formdata.append("celular", dataForm.celular);
                formdata.append("email", dataForm.email);
                formdata.append("landing", LandingActual);
                var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
                };
    
                fetch("https://weorderit.com/API2/getLead/v1/", requestOptions)
                .then(response => response.text())
                .then(result => {
                    SetLoading(false)
                    SetSend(true)
                    console.log(result)
                    ReactGA.event({
                        category: `${LandingActual} - ContactForm`,
                        action: `Solicitud de Contacto - ${dataForm.name}`,
                        label: `${dataForm.name} - ${dataForm.restaurante} - ${dataForm.celular}`
                    });
                })
                .catch(error => console.log('error', error));
            }
        }
    }
    const handleShowForm = ()=>{
        const conoceMas = document.querySelector('.conoceMas')
        conoceMas.classList.add('outStep')
        ReactGA.event({
            category: `${LandingActual} - Ver PDF`,
            action: `Solicitud de Contacto - ${medio}`,
            label: `visitor`
        });
    }
    const[send, SetSend]=useState(false)
    const[loading, SetLoading]=useState(false)
    return(
        <div className="contactForm">
        <div className="conoceMas"> <h3>Conoce más detalles</h3>
            <Button size="large" onClick={handleShowForm} variant="contained" color="secondary">
                {loading?'Enviando...':'Descargar PDF'}
            </Button></div>
            {send?
            <div className="confirmSend">
                <p>{dataForm.name}, hemos enviado un email a tu correo  <strong>{dataForm.email}</strong>, con nuestro PDF y más información  sobre <strong>WeOrder<span>-</span>it</strong></p>
                <p>Si en <span>5 minutos </span>no ves el correo, por favor <span>revisa todas tus bandejas: spam, promociones, social...</span></p>
            </div>
            :
            <>
            <div className="TitleEnLineaF"><strong>Ingresa tus datos</strong><br></br>  y recibirás un correo con más<br></br> información sobre <strong>WeOrder<span>-</span>it.</strong></div>
            <div className="inputContent">
                <TextField  variant="outlined" label="Nombre" error={isError.name}  name="name" onChange={(e)=>{handleDataForm(e, inp_field['name'])}} onKeyUp={(e)=>{handleDataForm(e, inp_field['name'])}}   placeholder="Ej: Nombre Apellido"  value={dataForm.name}  type="text"/>
            </div>
            <div className="inputContent">
                <TextField  variant="outlined" label="Nombre del Negocio" error={isError.restaurante}  name="restaurante" onChange={(e)=>{handleDataForm(e, inp_field['company'])}} onKeyUp={(e)=>{handleDataForm(e, inp_field['company'])}}   placeholder="Ej: Mi Negocio, S.A."  value={dataForm.restaurante}  type="text"/>
            </div>
            <div className="inputContent">
                <TextField  variant="outlined" label="Celular" error={isError.celular}  name="celular" onChange={(e)=>{handleDataForm(e, inp_field['name'])}} onKeyUp={(e)=>{handleDataForm(e, inp_field['name'])}}   placeholder="Ej:+507 6643-9579"  value={dataForm.celular}  type="phone"/>
            </div>
            <div className="inputContent">
                <TextField  variant="outlined" label="Email" error={isError.email}  name="email" onChange={(e)=>{handleDataForm(e, inp_field['email'])}} onKeyUp={(e)=>{handleDataForm(e, inp_field['email'])}}   placeholder="Ej: nombre@empresa.com"  value={dataForm.email}  type="email"/>
            </div>
            <Button size="large" onClick={handleSendEmail} variant="contained" color="secondary">
                {loading?'Enviando...':'Enviar'}
            </Button></>
            }
            <div className="logoFinal">
                <LogoFinal />
            </div>
        </div>
    )
}
export default ContactForm
