import React from 'react';
import ReactGA from 'react-ga';
import {Link ,useHistory} from 'react-router-dom'
const PDFView = (props)=>{
    const {landing, company, name, celular, email} = props.match.params;
    ReactGA.initialize('UA-173445859-2');
        ReactGA.event({
            category: `${landing} - PDF`,
            action: `${window.atob(name)} - ${window.atob(company)} - ${window.atob(celular)}`,
            label: `Visto`
        });
return(
    <iframe src="https://weorderit.com/WeOrder-it_Business.pdf" className="iframePdf" ></iframe>
)
}

export default PDFView
