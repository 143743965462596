
const reducer = (state, action) => {
  //return (state);
  switch (action.type) {
    case 'GET_INVOICE':
      //console.log('getInvoice')
      //console.log(action.payload.detalle)
      return {
        ...state,
        dataInvoice: action.payload,
        encabezado: action.payload.encabezado[0],
        detalle: action.payload.detalle,
        disponibilidad: action.payload.detalle,
        invoice: action.payload.encabezado[0].INVOICE_NO,
        domicilio: action.payload.domicilio[0],
        correo: action.payload.correo,
      };
    case 'SET_MENU':
      return {
        ...state,
        menu: action.payload,
      };
    case 'UPDATE_DISPONIBILITY':
      const mergeInventory = state.detalle.map((item, index) => ({ ...item, DISPONIBLE: action.payload[index].DISPONIBLE }));
      //state.detalle = mergeInventory
      return {
        ...state,
        disponibilidad: mergeInventory,
      };
    case 'SET_ENCABEZADO':
      console.log(action.id);
      return {
        ...state,
        encabezado: {
          ...state.encabezado,
          SUB_TOTAL: action.payload.SUB_TOTAL,
        },
      };
    case 'SET_NO_DISPONIBILITY':
      //console.log(action.id)
      //console.log(action.payload)
      return {
        ...state,
      };
    case 'SET_DATA_TITULAR':
      state.dataInvoice.encabezado[0].FIRST_NAME = action.payload.name;
      state.dataInvoice.encabezado[0].LAST_NAME = action.payload.lastname;
      return {
        ...state,
        dataInvoice: state.dataInvoice,
        card: { 'name': action.payload.name, 'lastname': action.payload.lastname, 'cardNumber': action.payload.cardNumber, 'expiration': action.payload.expiration, 'cvv': action.payload.cvv, 'status': action.payload.status },
      };
    case 'SET_DATA_DIRECTION':
      return {
        ...state,
        dataDirection: action.payload,
      };
    case 'SET_STOP_INVENTORY':
      return {
        ...state,
        checkInventory: false,
      };
    case 'UPDATE_MENU_INDEX':
      return {
        ...state,
        menuIndex: action.payload,
      };
    case 'ACTUAL_MENU':
      return {
        ...state,
        actualMenu: action.payload,
      };
    case 'ACTUAL_MENU':
      return {
        ...state,
        actualMenu: action.payload,
      };
    case 'UPDATE_MODEL_SPECS':
      //console.log(action.payload.map((version, index) =>{return version}))
      state.ModelsSelected = action.payload.map((version, index) => { return version; });
      return {
        ...state,
        versionModels: state.ModelsSelected,
      };
    case 'SET_AVAILABLE_MODELS':
      return {
        ...state,
        Available: action.payload.Available,
      };
    case 'SET_INFO_VIEW':
      const selectedModel = action.payload.modelsInfoView.map((version, index) => { return version[0]; });
      return {
        ...state,
        sucursales: action.payload.sucursales,
        prospect: action.payload.prospect,
        ModelListView: action.payload.ModelListView,
        modelsInfoView: action.payload.modelsInfoView,
        versionModels: action.payload.modelsInfoView.map((version, index) => { return version[0]; }),
        selectedModel: selectedModel[0],
        menuIndex: action.payload.modelsInfoView.map((version, index) => { return { 'menu': index, 'target': 0 }; }),
        colores: action.payload.colores,
      };
    case 'ADD_CONTENT_MODAL':
      return {
        ...state,
        contentModal: action.payload,
        ShowModal: true,
        activeHideModal: false,
      };
    case 'ADD_CONTENT_MODAL_CONTACT':
      return {
        ...state,
        contentModal: action.payload,
        ShowModal: true,
        activeHideModal: true,
      };
    case 'HIDE_MODAL':
      return {
        ...state,
        contentModal: null,
        ShowModal: false,
      };
    case 'SET_MODELS':
      return {
        ...state,
        ModelsSelected: [...state.ModelsSelected, action.payload],
        ModelsSelectedDefault: [],
      };
    case 'SET_MODELS_DEFAULT':
      return {
        ...state,
        ModelsSelected: [...state.ModelsSelected, action.payload],
        ModelsSelectedDefault: [...state.ModelsSelectedDefault, action.payload],
      };
    case 'DELETE_MODELS':

      return {
        ...state,
        ModelsSelected: state.ModelsSelected.filter((item) => item.modelo.ID !== action.payload),
      };
    case 'CLEAN_MODELS':
      return {
        ...state,
        ModelsSelected: [],
        formUser: [],
      };
    case 'LOGIN_REQUEST':
      return {
        ...state,
        user: action.payload,
      };
    case 'LOGOUT_REQUEST':
      return {
        ...state,
        user: action.payload,
      };
    case 'REGISTER_REQUEST':
      return {
        ...state,
        user: action.payload,
      };
    case 'FORM_REQUEST':
      return {
        ...state,
        formUser: action.payload,
      };
    case 'GET_VIDEO_SOURCE':
      return {
        ...state,
        playing: state.trends.find((item) => item.id === Number(action.payload)) ||
                state.originals.find((item) => item.id === Number(action.payload)) || [],
      };
      //makeURL
    case 'SET_MODELS_LIST':
      return {
        ...state,
        modelsList: action.payload,
        filterCars: action.payload,
      };
    case 'FILTER_CARS':
      return {
        ...state,
        filterCars: action.payload,
      };
    case 'GET_SUCURSALES':
      return {
        ...state,
        sucursales: action.payload,
      };
    case 'GET_VENDEDORES':
      return {
        ...state,
        vendedores: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
