import React, {useState} from 'react'
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider2 from '@material-ui/core/Slider';
import Slider from "react-slick";
import ReactGA from 'react-ga';
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    );
  }

const PrettoSlider = withStyles({
    root: {
      color: '#E0E0E0',
      height: 4,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#FF4040',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
     
    },
    track: {
      height: 4,
      borderRadius: 4,
      backgroundColor:'rgba(150, 150, 150, 0.43)'
    },
    rail: {
      height: 4,
      borderRadius: 4,
    },
  })(Slider2);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '90%',
      padding:'20px 20px 0px 20px'
    },
    margin: {
      height: theme.spacing(3),
    },
  }));
  function formatearNumero(nStr) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1+x2;
}
const Calculadora = ({medio})=>{
  medio == undefined?medio='organic':medio=medio
    function valuetext(value) {
        return `$${value}`;
    }
    const montoInicial = 300
    const [value, setValue] = React.useState(montoInicial);
    const handleChange = (event, v)=>{
      ReactGA.initialize('UA-173445859-2');
        setValue(v)
        SetPercentActive({
            ...percentActive,
            "percent":percentActive.percent,
            "daily":((v*percentActive.percent/100)*1),
            "monthly":((v*percentActive.percent/100)*30),
            "annual":((v*percentActive.percent/100)*365),
        })

    }
    const handleLeave=(event, v)=>{
      console.log('leave')
      ReactGA.initialize('UA-173445859-2');
      ReactGA.event({
        category: 'CalculadoraGastos',
        action: `Venta Diaria - ${medio}`,
        label: `${percentActive.percent} - ${v}`
    });
    }
    const [percentActive, SetPercentActive]=useState({"percent":15,"daily":300*15/100,"monthly":((300*15/100)*30), "annual":((300*15/100)*365)})
    const handleChangePercent=(event, percent)=>{
      ReactGA.initialize('UA-173445859-2');
        const targetDay = event.currentTarget
        const ElementsPercent = document.querySelectorAll('.btnPercent')
        ElementsPercent.forEach(function(el) {
            const ClassExist = el.classList.contains('active')
            ClassExist?el.classList.remove("active"):''
        })
        targetDay.classList.add('active')
        SetPercentActive({
            ...percentActive,
            "percent":percent,
            "daily":((value*percent/100)*1),
            "monthly":((value*percent/100)*30),
            "annual":((value*percent/100)*365),
        })
        ReactGA.event({
          category: 'CalculadoraGastos',
          action: `Porcentaje  - ${medio}`,
          label: `${percent} - ${value}`
      });
    }
    const SimpleSlider =()=> {
        //console.log(percentActive)
            var settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 1500,
            autoplaySpeed: 8000,
            slidesToShow: 1,
            slidesToScroll: 1
            };
        return (
            <Slider {...settings}>
                <div className="slideText">
                    <h3>Lo que <span>gastas</span> <strong>por día</strong></h3>
                    <h2>${percentActive.daily}</h2>
                </div>
                <div className="slideText">
                    <h3>Lo que <span>gastas</span> <strong>mensual</strong> </h3>
                    <h2>${formatearNumero(percentActive.monthly)}</h2>
                </div>
                <div className="slideText">
                <h3>Lo que <span>gastas</span> <strong>anual</strong></h3>
                    <h2>${formatearNumero(percentActive.annual)}</h2>
                </div>
            </Slider>
        );
      }
    const classes = useStyles();
    return(
        <div className="contentCalculadora">
                <div className="minText2">Tus <strong>ventas</strong> por día</div>
          <div className={classes.root}>
            <PrettoSlider
            min={200}
            max={1500}
            defaultValue={700}
            getAriaValueText={valuetext}
            valueLabelFormat={valuetext}
            aria-labelledby="discrete-slider-always"
            step={100}
            onChange={(event, v) =>handleChange(event,v)}
            onChangeCommitted={(event, v) =>handleLeave(event,v)}
            valueLabelDisplay="on"/>
        </div>

        <div className="minText">Lo que <strong>pagas en comisión</strong> </div>
        <div className="contantButtons">
            <div className="btnPercent active" onClick={(event)=>handleChangePercent(event, 15)}>15%</div>
            <div className="btnPercent" onClick={(event)=>handleChangePercent(event, 20)}>20%</div>
            <div className="btnPercent" onClick={(event)=>handleChangePercent(event, 25)}>25%</div>
            <div className="btnPercent" onClick={(event)=>handleChangePercent(event, 30)}>30%</div>
        </div>

        <div className="slideContainer">
                <SimpleSlider />
            </div>
        </div>
    )
}
export default Calculadora
