export const GetInvoice = payload => ({
    type:'GET_INVOICE',
    payload,
})
export const SetMenu = payload => ({
    type:'SET_MENU',
    payload,
})
export const SetDataDirection = payload => ({
    type:'SET_DATA_DIRECTION',
    payload,
})
export const setStopCheckInventory = payload =>({
    type:'SET_STOP_INVENTORY',
    payload,
})
export const UpdateData = payload => ({
    type:'UPDATE_DATA',
    payload,
})
export const SetEncabezado = (payload, id)=> ({
    type:'SET_ENCABEZADO',
    payload,
    id,
})
export const SetNoDisponibility = (payload, id)=> ({
    type:'SET_NO_DISPONIBILITY',
    payload,
    id,
})

export const UpdateDisponibility = (payload)=> ({
    type:'UPDATE_DISPONIBILITY',
    payload,
})

export const addContentModal = payload => ({
    type:'ADD_CONTENT_MODAL_CONTACT',
    payload,
})
export const addContentModalContact = payload => ({
    type:'ADD_CONTENT_MODAL',
    payload,
})
export const hideModal = payload => ({
    type:'HIDE_MODAL',
    payload,
})