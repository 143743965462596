import React, {useState} from 'react'
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider2 from '@material-ui/core/Slider';
import Slider from "react-slick";
import ReactGA from 'react-ga';

import Whats1 from '../assets/static/whats1.png'
import Whats2 from '../assets/static/whats2.png'
import Whats3 from '../assets/static/whats3.png'
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    );
  }

const PrettoSlider = withStyles({
    root: {
      color: '#E0E0E0',
      height: 4,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#FF4040',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
     
    },
    track: {
      height: 4,
      borderRadius: 4,
      backgroundColor:'rgba(255, 66, 66, 0.43)'
    },
    rail: {
      height: 4,
      borderRadius: 4,
    },
  })(Slider2);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '90%',
      padding:'20px'
    },
    margin: {
      height: theme.spacing(3),
    },
  }));
  function formatearNumero(nStr) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1+x2;
}
const Calculadora = ()=>{

    const SimpleSlider =()=> {
        //console.log(percentActive)
            var settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 1500,
            autoplaySpeed: 8000,
            slidesToShow: 1,
            slidesToScroll: 1
            };
        return (
            <Slider {...settings}>
                <div className="slideText2" style={{display:'grid',justifyContent:'center'}}>
                <img src={Whats1} height={180}/>
                  <p>¿Aún gestionas tus pedidos<br></br>
                  por <span className="textwhats1">mensajes de texto</span>?</p>
                </div>
                <div className="slideText2" style={{display:'grid',justifyContent:'center'}}>
                <img src={Whats2} height={180}/>
                  <p>¿Pierdes clientes por<br></br>
                  <span className="textwhats2">baja eficiencia</span>?</p>
                </div>
                <div className="slideText2" style={{display:'grid',justifyContent:'center'}}>
                  <img src={Whats3} height={180}/>
                  <p>¿Quieres que tu restaurante<br></br>
                  <span className="textwhats3">venda en línea</span>?</p>
                </div>
            </Slider>
        );
      }
    const classes = useStyles();
    return(
        <div className="contentWhats">
        <div className="slideContainer">
                <SimpleSlider />
            </div>
        </div>
    )
}
export default Calculadora
