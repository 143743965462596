import React from 'react';
import LogoFinal from '../assets/static/logoFinal.svg'
import Button from '@material-ui/core/Button';
import {Link ,useHistory} from 'react-router-dom'
const NotFound = () => {
  let history = useHistory()
  const handleGoToHome =()=>{
    history.push('/')
  }
  return(
  <div className="notfound">
    <LogoFinal />
    <h2>Ruta no disponible</h2>
    <Button size="large" onClick={handleGoToHome} variant="contained" color="secondary">
        Ir a inicio
    </Button>
  </div>
)};

export default NotFound;
