import React, {useEffect, useState} from 'react';
import {Link ,useHistory} from 'react-router-dom'
import '../assets/styles/styles.scss';
import Modal from '../components/Modal';
import Whatsapp from '../assets/static/icon_whatsapp.svg'
import ReactGA from 'react-ga';
const Layout = ({children, match}) => {
    let history = useHistory()

    let rutaActual = history.location.pathname
    let LandingActual = ''
    if(rutaActual.includes('/L1/')){
        LandingActual = 'CalculadoraAhorro'
    }else
    if(rutaActual.includes('/L2/')){
        LandingActual = 'NecesitasOrderit'
    }else
    if(rutaActual.includes('/PDF/')){
        LandingActual = 'VisualizaPDF'
    }else{
        LandingActual = 'CalculadoraGastos'
    }
    const handleGA=()=>{
        ReactGA.initialize('UA-173445859-2');
        ReactGA.event({
            category: `${LandingActual} - Whatsapp`,
            action: 'Flotante',
            label: `Click`
        });
    }
    return(
        <div className="App">
            <Link onClick={handleGA} to={{ pathname: `https://api.whatsapp.com/send?phone=+50766439579&text=%20` }} target="_blank" >
                <Whatsapp className="whast" heigth="40" />
            </Link>
            {children}
            <Modal/>
        </div>
    )};

export default Layout