import React, { useState, useEffect } from 'react'
import {Link ,useHistory} from 'react-router-dom'
import { connect } from 'react-redux';
import {addContentModalContact,hideModal}  from '../actions';
import {Parallax, ParallaxLayer} from 'react-spring/renderprops-addons'
import {Spring, config} from 'react-spring/renderprops'
import { useSwipeable, Swipeable } from 'react-swipeable'
import Calculadora from '../components/Calculadora'
import LogoOrderIt from '../assets/static/logo_order-it_p2.svg'
import PrecioP1 from '../assets/static/7_por_dia.svg'
import IconTienda from '../assets/static/icon_tienda_en_linea.svg'
import IconPedidos from '../assets/static/icon_pedidos.svg'
import IconDelivery from '../assets/static/icon_delivery.svg'
import CirculoNaranjaHueco from '../assets/static/circulo_naranja_hueco.svg'
import IconSucursales  from '../assets/static/sucursales_p5.svg'
import Escribenos from '../assets/static/boton_escribenos.svg'
import WhatsappHeader from '../components/WhatsappHeader'
import ContactForm from '../components/ContactForm'
import Modal from '../components/Modal';
import ReactGA from 'react-ga';
import LogoFinal from '../assets/static/logoFinal.svg'
import CalculaAhorro from '../components/CalculaAhorro'
import HeaderCalculo from '../assets/static/header_comisiones.png'
import Ahorras from '../assets/static/ahorras.png'

// Little helpers ...
const url = (name, wrap = false) => `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`

const handleSwipe =(e)=>{
    if(e.dir =="Up"){
        console.log("up")
    }
    if(e.dir =="Down"){
        console.log("Down")
    }
}
const handleClick=(e)=>{
    console.log(e.scrollTo(1))
    console.log(e)
}


const  App=({addContentModalContact,hideModal, match})=>{

    const {medio} = match.params;
    console.log('medio')
    console.log(medio)
    let history = useHistory()
    let rutaActual = history.location.pathname
    console.log(history)
    rutaActual = rutaActual.replace(`${medio}`,'')
    console.log(rutaActual)
    let LandingActual = ''
    if(rutaActual == '/'){
        LandingActual = 'NecesitasOrderit'
    }
    if(rutaActual == '/L1/'){
        LandingActual = 'CalculadoraAhorro'
    }
    if(rutaActual == '/L2/'){
        LandingActual = 'CalculadoraGastos'
    }
    const handleWhats=()=>{
        ReactGA.initialize('UA-173445859-2');
        ReactGA.event({
            category: `${LandingActual} - Whatsapp - ${medio}`,
            action: 'Estatico',
            label: `Click`
        });
    }

    var clientHeight = document.getElementById('app').clientHeight;
    const [xHeight,SetXHeight] =useState(clientHeight);
    useEffect(()=>{
        SetXHeight(clientHeight);
        console.log(xHeight)
    })
    const handleModal=()=>{
        console.log('clickModal')
        // addContentModalContact(
        //     <ContactForm hideModal={hideModal}/>
        // )
    }
    return (
      <Parallax config={config.wobbly} scrolling={true} style={{height:`${xHeight}px`}} pages={6}>
        {/* <ParallaxLayer offset={1} speed={1} style={{ backgroundColor: '#fff' }} />
        <ParallaxLayer offset={2} speed={1} style={{ backgroundColor: '#fff' }} />*/}
        {rutaActual == '/L2/'?<ParallaxLayer
            offset={0}
            speed={0}
            onClick={() => handleClick(this.parallax)}
            style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', }}>
            <img src={HeaderCalculo} height={155}/>
        </ParallaxLayer>:null}
        {rutaActual == '/L1/'?<ParallaxLayer
            offset={0}
            speed={0}
            onClick={() => handleClick(this.parallax)}
            style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', }}>
            <img src={Ahorras} height={180}/>
        </ParallaxLayer>:null}
        {rutaActual == '/'?<>
        <ParallaxLayer
            offset={0}
            speed={0.2}
            onClick={() => handleClick(this.parallax)}
            style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', backgroundColor:'#fff' }}>
            <WhatsappHeader />
        </ParallaxLayer>
        <ParallaxLayer offset={0.54}  speed={0.3} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <div className="TitleEnLinea">...Entonces <strong>necesitas</strong></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0.63} className="orderitDesc" speed={0.3} style={{ alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <LogoFinal />
            <div style={{width:'100%', marginTop:'10px'}}>Tienda en línea | Administrador | Domicilio</div>
        </ParallaxLayer>
        <ParallaxLayer offset={0.5}  speed={0} style={{ opacity: 0.1 }}>
            <div className="circleNaranja" style={{ display: 'block', width: '185px', height:'185px', marginLeft: '80%' }} />
        </ParallaxLayer>
        <ParallaxLayer offset={0.5}  speed={0} style={{ opacity: 0.2 }}>
            <div className="circleAzul" style={{ display: 'block', width: '100px', height:'100px', marginLeft: '-10%' }} />
        </ParallaxLayer>
        </>:null
        }
        {rutaActual == '/L2/'?<ParallaxLayer
            offset={0.22}
            speed={0.1}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height:'410px' }}>
            <Calculadora medio={medio} />
        </ParallaxLayer>:null}
        {rutaActual == '/L1/'?<ParallaxLayer
            offset={0.21}
            speed={0.1}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height:'410px' }}>
            <CalculaAhorro medio={medio}/>
        </ParallaxLayer>:null}
        <ParallaxLayer offset={0.84}  speed={0} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'11' }} >
            <div className="circleGris" />
        </ParallaxLayer>
        <ParallaxLayer offset={0.85}  speed={0} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <PrecioP1 heigth="40"  style={{transform:'scale(1.3)'}} />
        </ParallaxLayer>

        <ParallaxLayer offset={1.06} onClick={() => handleClick(this.parallax)} speed={0.1} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <LogoOrderIt heigth="40"   />
        </ParallaxLayer>

        <ParallaxLayer offset={1.25}  speed={0.1} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <IconTienda heigth="40"   />
        </ParallaxLayer>
        <ParallaxLayer offset={1.52}  speed={0.2} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <div className="TitleEnLinea">Tu negocio <span> en línea</span></div>
        </ParallaxLayer>
        <ParallaxLayer offset={1.56}  speed={0.2} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <div className="contentEnLinea">
                <p><strong>La mejor opción para vender fácil y rápido.</strong></p>
                <p>Tus clientes podrán pedirte con unos cuantos toques.</p>
            </div>
        </ParallaxLayer>
        <ParallaxLayer offset={1.7}  speed={0} style={{ opacity: 0.2 }}>
            <div className="circleNaranja" style={{ display: 'block', width: '235px', height:'235px', marginLeft: '80%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={1.3} speed={0} style={{ opacity: 0.4 }}>
            <CirculoNaranjaHueco  style={{ display: 'block', width: '90%', marginLeft: '-45%' }} />
        </ParallaxLayer>

        {/**TERCERA PANTALLA ADMINISTRADOR */}
        {/* <ParallaxLayer offset={1.88}  speed={0.16} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <div className="interline1">Imagina gestionar tus pedidos <br></br><span>500% más rápido</span>
            <Escribenos height={51}/>
            </div>
        </ParallaxLayer> */}
        <ParallaxLayer offset={2.1}  speed={-0.1} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <IconPedidos heigth="40"   />
        </ParallaxLayer>
        <ParallaxLayer offset={2.1}  speed={0} style={{ opacity: 0.1 }}>
            <div className="circleAzul" style={{ display: 'block', width: '235px', height:'235px', marginLeft: '-30%' }} />
        </ParallaxLayer>
        <ParallaxLayer offset={2.7}  speed={0} style={{ opacity: 0.1 }}>
            <div className="circleAzul" style={{ display: 'block', width: '135px', height:'135px', marginLeft: '80%' }} />
        </ParallaxLayer>
        <ParallaxLayer offset={2.35}  speed={-0.1} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <div className="TitlePedidos">Administrador<br></br>de <span> Pedidos</span></div>
        </ParallaxLayer>
        <ParallaxLayer offset={2.50}  speed={0.1} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <div className="contentPedidos">
                <p>Gestiona desde una sola pantalla toda tu operación. <br></br><br></br>
                <strong>Optimiza el proceso de pedidos.</strong><br></br>
                <br></br>
            Aumenta tu rendimiento, <strong>hazlo 10 veces más rápido </strong></p>
            </div>
            {/******PANTALLA DOMICILIO */}
        </ParallaxLayer>
        <ParallaxLayer offset={3.1}  speed={-0.1} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <IconDelivery heigth="40"   />
        </ParallaxLayer>
        <ParallaxLayer offset={3.1}  speed={0} style={{ opacity: 0.1 }}>
            <div className="circleVerde" style={{ display: 'block', width: '235px', height:'235px', marginLeft: '-30%' }} />
        </ParallaxLayer>
        <ParallaxLayer offset={3.7}  speed={0} style={{ opacity: 0.1 }}>
            <div className="circleVerde" style={{ display: 'block', width: '135px', height:'135px', marginLeft: '80%' }} />
        </ParallaxLayer>
        <ParallaxLayer offset={3.35}  speed={0.1} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <div className="TitleDelivery">Gestión<br></br>de <span> Domicilio</span></div>
        </ParallaxLayer>
        <ParallaxLayer offset={3.47}  speed={0.1} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <div className="contentPedidos">
                <p>
                Todo lo que necesitas para gestionar <strong>tu propio equipo de domicilio.</strong><br></br><br></br>
                Cálculo de costo de domicilio.<br></br><br></br>
                Integración de mapas con rutas de navegación.<br></br><br></br> Datos del cliente y todo el detalle del pedido. </p>
            </div>
        </ParallaxLayer>
        {/****PANTALLA SUC?URSALES */}
        <ParallaxLayer
            offset={4.1}
            speed={-0.05}
            style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }}>
            <IconSucursales heigth="40"   />
        </ParallaxLayer>

        <ParallaxLayer offset={4.38}  speed={0.3} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <div className="TitleEnLinea">Administra<br></br> <span> Multiples Sucursales</span></div>
        </ParallaxLayer>
        <ParallaxLayer offset={4.46}  speed={0.3} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <div className="contentEnLinea">
                <h4>Juntos pero no revueltos.</h4>
                <p> Gestiona todas tus sucursales de forma <strong>fácil y rápida.</strong></p>
            </div>
        </ParallaxLayer>
        <ParallaxLayer
            offset={4.1}
            speed={0.2}
            style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }}>
            <IconSucursales heigth="40" style={{marginLeft: '-175px', transform:'scale(0.7)'}}  />
        </ParallaxLayer>
        <ParallaxLayer
            offset={4.1}
            speed={0.2}
            style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }}>
            <IconSucursales heigth="40" style={{marginLeft: '175px', transform:'scale(0.7)'}}  />
        </ParallaxLayer>
        <ParallaxLayer
            offset={4.8}
            speed={-0.05}
            style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }}>
                <Link onClick={handleWhats} to={{ pathname: `https://api.whatsapp.com/send?phone=+50766439579&text=%20` }} target="_blank" >
                    <Escribenos heigth="40"   />
                </Link>
        </ParallaxLayer>
        <ParallaxLayer offset={4.7}  speed={0} style={{ opacity: 0.2 }}>
            <div className="circleNaranja" style={{ display: 'block', width: '235px', height:'235px', marginLeft: '80%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={4.3} speed={0} style={{ opacity: 0.4 }}>
            <CirculoNaranjaHueco  style={{ display: 'block', width: '90%', marginLeft: '-45%' }} />
        </ParallaxLayer>
        {/****PANTALLA CXONTACTO */}


        <ParallaxLayer  offset={5.05}  speed={0.3} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', zIndex:'12' }} >
            <div onClick={handleModal}><ContactForm medio={medio} /></div>
        </ParallaxLayer>
        <Modal />
    </Parallax>
    )
}

const mapDispatchToProps = {
    addContentModalContact,
    hideModal
  }
  export default connect(null, mapDispatchToProps)(App);
