import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, compose } from 'redux';
import App from './routes/App';
import reducer from './reducers';

const makeURL = window.location.href;

const initialState = {
  'menu': { 'modelos': [], 'accompaniment': [], 'extras': [], 'subtype': [], 'sucursal': []},
  "ShowModal":false,
  'contentModal': null 
};

//"prospect":[],"ModelListView":[],"modelsInfoView":[]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, initialState, composeEnhancers());
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app'),
);
